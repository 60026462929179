<template>
  <div class="mt-8">
     <div class="">
      <div v-if="listTitle !== ''" class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white py-4 px-4 rounded-xl absolute -top-8 w-full">
          <div class=" relative flex items-center justify-between">
            <div>
              {{listTitle}} ({{events.length}})
            </div>
          </div>
        </div>
      </div>
      <div class="pt-8">
        <div>
          <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="events.length > 0">
            <div v-for="(data, index) in events" :key="index"  class="cust_card mb-4  heading-3 border-b border-gray1  bg-white  text-sm p-3 items-center hover:bg-gray1 cursor-pointer" @click.stop="showJobDetail(data)">
              <div class="">
                <!-- <p class="  text-text1 font-bold">{{}}</p> -->
                <div class="flex justify-between items-center">
                  <div class="flex items-center">
                    <span v-html="getIcons(data.type)" class="pr-2"></span>
                    <p v-if="data.displayText !== ''" class="text-gray4 heading-5 overFlowParaA">{{data.displayText}}</p>
                      <p v-else class="text-text1 heading-4 py-1 font-semibold whitespace-pre-line">{{data.title}}</p>
                  </div>
                  <div class="text-gray4 heading-5 flex items-center">
                    {{data.dateStatus}}
                    <span v-if="data.dateStatus === 'Unschedule'" class="text-warning h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span>
                    <span v-if="data.dateStatus === 'Late'" class="text-error h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span>
                    <span v-if="data.dateStatus === 'Today'" class="text-secondary h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span>
                    <p v-if="data.dateStatus === 'Upcoming'" class="text-primary h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></p>
                  </div>
                </div>
                <div v-if="data.displayText !== ''" class="flex">
                  <p class="text-text1 heading-4 py-1 font-semibold whitespace-pre-line">{{data.title}}</p>
                </div>
                <div class="">
                  <p class="text-gray4 heading-5" v-if="data.company !== ''">{{data.company}}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="">
            <NoRecords :alertMessage="`No Visits, Request and task`" />
          </div>
        </div>
      </div>
     </div>
   </div>
</template>
<script>
import {FilterPermissions} from '@/utils/Permissions.js'
import {getDisplayText} from '@/utils/convertDateAndTime.js'
var moment = require('moment')
import NoRecords from '@/View/components/noRecords.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  props: ['scheduleData', 'scheduled', 'listTitle'],
  name: "scheduleData",
  components: {
    NoRecords,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      events: [],
    };
  },
  created() {},
  watch: {
    scheduleData: {
      handler () {
      //        console.log("scheduleData***************************************************************************************", this.scheduled);
      // console.log("scheduleData***************************************************************************************", this.scheduleData);
        this.configureCalenarEvents()
      },
      deep: true
    },
  },
  mounted() {
  },
  beforeDestroy() {},
  methods: {
    configureCalenarEvents () {
      let eventsArray = []
      if (this.scheduled) {
        for (let cIndex = 0; cIndex < this.scheduleData.length; cIndex++) {
            const childObject = this.scheduleData[cIndex]
            if (childObject.entityType !== 'job' && childObject.entityType !== 'request') {
              let sDate = new Date(childObject.startTime).toUTCString()
              sDate = moment(sDate).format('YYYY-MM-DD HH:mm')
              let eDate = new Date(childObject.endTime).toUTCString()
              eDate = moment(eDate).format('YYYY-MM-DD HH:mm')
              let mainObject = {
                id: 0,
                type: '',
                jobId: 0,
                title: '',
                resourceId: 0,
                color: '',
                start: '',
                end: '',
                obj: {},
                company: '',
              }
              mainObject.id = childObject.taskId
              mainObject.jobId = childObject.taskId
              mainObject.title = childObject.title
              mainObject.resourceId = childObject.taskId
              mainObject.color = childObject.isTaskCompleted ? '#8e8e8e' : '#055c58'
              mainObject.start = sDate
              mainObject.end = eDate
              mainObject.obj =  childObject
              mainObject.type =  childObject.entityType
              eventsArray.push(mainObject)
            } else {
              let sDate = new Date(childObject.start).toUTCString()
              sDate = moment(sDate).format('YYYY-MM-DD HH:mm')
              let eDate = new Date(childObject.end).toUTCString()
              eDate = moment(eDate).format('YYYY-MM-DD HH:mm')
              let mainObject = {
                id: 0,
                type: '',
                jobId: 0,
                title: '',
                resourceId: 0,
                color: '',
                start: '',
                end: '',
                obj: {},
                company: childObject.customerName
              }
              if (childObject.isrequest) {
                mainObject.type = 'request'
                mainObject.id = childObject.jobId
                mainObject.jobId = childObject.visitId
                mainObject.resourceId = childObject.jobId
              } else {
                mainObject.type = 'job'
                mainObject.id = childObject.visitId
                mainObject.jobId = childObject.jobId
                mainObject.resourceId = childObject.visitId
              }
              mainObject.title = childObject.title
              mainObject.color = childObject.runningTimer > 0 ? '#F16C1F' : childObject.isCompleted ? 'green' : childObject.isrequest ? '#b0856d' : '#1295BA'
              mainObject.start = sDate
              mainObject.end = eDate
              mainObject.obj =  childObject
              eventsArray.push(mainObject)
            }
          }
      } else {
        // for (let pIndex = 0; pIndex < this.scheduleData.length; pIndex++) {
          // const parentObj = this.scheduleData[pIndex]
          for (let cIndex = 0; cIndex < this.scheduleData.length; cIndex++) {
            const childObject = this.scheduleData[cIndex]
            if (childObject.entityType !== 'job' && childObject.entityType !== 'request') {
              let sDate = new Date(childObject.startTime).toUTCString()
              sDate = moment(sDate).format('YYYY-MM-DD HH:mm')
              let eDate = new Date(childObject.endTime).toUTCString()
              eDate = moment(eDate).format('YYYY-MM-DD HH:mm')
              let mainObject = {
                id: 0,
                type: '',
                jobId: 0,
                title: '',
                resourceId: 0,
                color: '',
                start: '',
                end: '',
                obj: {},
                company: '',
              }
              mainObject.id = childObject.taskId
              mainObject.jobId = childObject.taskId
              mainObject.title = childObject.title
              mainObject.resourceId = childObject.taskId
              mainObject.color = childObject.isTaskCompleted ? '#8e8e8e' : '#055c58'
              mainObject.start = childObject.sDate !== "" ? sDate : '' 
              mainObject.end =  childObject.end !== "" ? eDate : ''
              mainObject.obj =  childObject
              mainObject.type =  childObject.entityType
              eventsArray.push(mainObject)
            } else {
              let sDate = new Date(childObject.start).toUTCString()
              sDate = moment(sDate).format('YYYY-MM-DD HH:mm')
              let eDate = new Date(childObject.end).toUTCString()
              eDate = moment(eDate).format('YYYY-MM-DD HH:mm')
              let mainObject = {
                id: 0,
                type: '',
                jobId: 0,
                title: '',
                resourceId: 0,
                color: '',
                start: '',
                end: '',
                obj: {},
                company: childObject.customerName
              }
              if (childObject.isrequest) {
                mainObject.id = childObject.jobId
                mainObject.jobId = childObject.visitId
                mainObject.resourceId = childObject.jobId
              } else {
                mainObject.id = childObject.visitId
                mainObject.jobId = childObject.jobId
                mainObject.resourceId = childObject.visitId
              }
              mainObject.type =  childObject.entityType
              mainObject.title = childObject.title
              mainObject.color = childObject.runningTimer > 0 ? '#F16C1F' : childObject.isCompleted ? 'green' : childObject.isrequest ? '#b0856d' : '#1295BA'
              mainObject.start = childObject.sDate !== "" ? sDate : '' 
              mainObject.end =  childObject.end !== "" ? eDate : ''
              mainObject.obj =  childObject
              eventsArray.push(mainObject)
            }
          }
      }
      
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      currentDate = new Date(currentDate);
      const cFormated = moment(currentDate).format('YYYY-MM-DD');

      // console.log("eventsArrayeventsArrayeventsArrayeventsArrayeventsArrayeventsArray", this.scheduled);
      // console.log("eventsArrayeventsArrayeventsArrayeventsArrayeventsArrayeventsArray", eventsArray);

      // const array = eventsArray
      // const key = 'jobId'
      // const arrayUniqueByKey = [...new Map(array.map(item =>
      //   [item[key], item])).values()]
      this.events = eventsArray

      for (let index = 0; index < this.events.length; index++) {
        if ( moment(new Date(this.events[index].start)).format('YYYY-MM-DD',) === cFormated) {
          this.events[index].dateStatus = 'Today';
        } else if ( new Date(this.events[index].start).getTime() < currentDate.getTime()) {
          this.events[index].dateStatus = 'Late';
        } else if (new Date(this.events[index].start).getTime() > currentDate.getTime()) {
          this.events[index].dateStatus = 'Upcoming';
        } else {
          this.events[index].dateStatus = 'New';
        }
        if (this.events[index].start !== '' && this.events[index].end !== '') {
          this.events[index].displayText = getDisplayText(this.events[index].start, this.events[index].end)
        } else {
         this.events[index].displayText = ''
        }
      }
    },
    getIcons (data) {
      if (data === 'visit') {
        return `<span class="text-primary heading-4"><i class="fas fa-calendar-alt"></i></span>`
      } else if (data === 'job') {
        return `<span class="text-primary heading-4"><i class="fas fa-briefcase"></i></span>`
      } else if (data === 'request') {
        return `<span class="text-primary heading-4"><i class="fa-solid fa-code-pull-request"></i></span>`
      } else if (data === 'task') {
        return `<span class="text-primary heading-4"><i class="fa-solid fa-clipboard-list"></i></span>`
      }
    },
    showJobDetail (data) {
      console.log('ddddddddd', data);
      let startDt = moment(data.start).format(this.$store.getters.dateTimeFormate)
      if (data.obj.isrequest && data.type === 'request') {
        let temp = FilterPermissions('request')
        if (temp) {
          this.$router.push({name: 'requestDetail', params: {requestId: data.obj.jobId}})
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Request detail`})
        }
      } else if (!data.obj.isrequest && data.type === 'job') {
        let temp = FilterPermissions('job')
        if (temp) {
          if (!this.scheduled) {
            this.$router.push({name: 'jobDetail', params: {jobId: data.obj.jobId}})
          } else {
            this.$router.push({name: 'visitDetail', params: {visitId: data.obj.visitId}})
          }
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Visit detail`})
        }
      } else if (!data.obj.isrequest && data.type === 'task') {
        let temp = FilterPermissions('task')
        if (temp) {
          this.$router.push({name: 'TaskDetail', params: {taskId: data.obj.taskId}, query: {date: startDt}})
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Task detail`})
        }
      }
    },
  }
};
</script>
<style scoped>
</style>