<template>
  <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 1000px; overflow: auto; max-height: 85%;">
      <div class="relative">
        <div class="">
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3">
                Schedule Visit
            </div>
          </div>
          <div class="p-4">
            <SheduleVisit :isFromSchedule="true" :sTime="startTime" :eTime="endTime" :isSelect="true" :title="'Schedule'" :showEmployeeSelection="true" :showJobItemSelection="true" :type="'oneVisit'" />
          </div>
        </div>
          <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="addNewVisit()"/>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import SheduleVisit from '@/View/job/components/SheduleVisit.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
  name: 'sendEmail',
  components: {
    Button,
    SheduleVisit,
  },
  data () {
    return {
        visitData: {
          jobId: 0,
          visitStartDateTime: '',
          visitEndDateTime: '',
          assignToList: [],
          itemList: [],
        },
    }
  },
  props: ['detail', 'startTime', 'endTime'],
   created() {
  },
  mounted () {
    this.visitData.jobId = this.detail.jobId,
    document.body.style = 'overflow: hidden;'
    this.$root.$on('setItemSelectedHandler', (response) => {
      this.visitData.itemList = response
    })
    this.$root.$on('finalvisitlistHandler', (response) => {
      if (response.length > 0) {
        this.visitData.visitStartDateTime = response[0].visitStartDateTime
        this.visitData.visitEndDateTime = response[0].visitEndDateTime
      }
    })
    this.$root.$on('setSelectedEmpHandler', (response) => {
      this.visitData.assignToList = response
    })
  },
  beforeDestroy () {
    this.$root.$off("setItemSelectedHandler");
    this.$root.$off("finalvisitlistHandler");
    this.$root.$off("setSelectedEmpHandler");
  },
  watch: {},
  methods: {
    addNewVisit () {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
        MyJobApp.addJobVisit(
          this.visitData,
          (response) => {
              document.body.style = 'overflow: visible;'
              this.$root.$emit('addExistingJobHandler', true)
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            },
            (error) => {
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            }
        );
    },
    discard () {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('addExistingJobHandler', false)
    },
  },
}
</script>
<style scoped>

</style>