<template>
  <div
    :style="`height:${hideTimeline ? clientHeight : 'auto'}`"
    class="wrapper relative"
  >
    <div id="visualization"></div>
    <div
      v-if="hideTimeline"
      class="top-0 bottom-0 left-0 right-0 absolute w-full h-full bg-white flex justify-center items-center"
    >
      <div class="">
        <div class="text-gray4 heading-4 p-2 flex items-center">
          <div class="loader2 ease-linear mr-2"></div>
          Loading.....
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Timeline } from "vis-timeline/standalone";

export default {
  components: {
  },
  props: ["items", "groups", "options", "view"],
  data() {
    return {
      hideTimeline: true,
      clientHeight: "100vh",
    };
  },
  created() {},
  async mounted() {
    let s = new Date().setHours(6);
    let e = new Date().setHours(20);
    if (this.view === "user") {
      if (this.groups.length > 0) {
        this.groups.push({
          content: "static-rec",
          id: "static-rec",
        });
      }

      let idObj = {
        jobId: 0,
        userDetailId: 0,
        entityType: "static-rec",
        visitId: 0,
        startTime: new Date(s).toUTCString(),
        endTime: new Date(e).toUTCString(),
      };
      this.items.push({
        id: "static-rec",
        group: "static-rec",
        className: "static-rec",
        content: "",
        start: new Date(s).toUTCString(),
        end: new Date(e).toUTCString(),
        obj: JSON.stringify(idObj),
        style: "",
        selectable: true,
        itemsAlwaysDraggable: true,
      });
    } else {
      const findex1 = this.groups.findIndex((x) => x.id === "static-rec");
      if (findex1 < 0) {
        this.groups.push({
          content: "static-rec",
          id: "static-rec",
        });
      }
      const findex = this.items.findIndex((x) => x.group === "static-rec");
      if (findex < 0) {
        let idObj = {
          jobId: 0,
          userDetailId: 0,
          entityType: "static-rec",
          visitId: 0,
          startTime: new Date(s).toUTCString(),
          endTime: new Date(e).toUTCString(),
        };
        this.items.push({
          id: "static-rec",
          group: "static-rec",
          className: "static-rec",
          content: "",
          start: new Date(s).toUTCString(),
          end: new Date(e).toUTCString(),
          obj: JSON.stringify(idObj),
          style: "",
          selectable: true,
          itemsAlwaysDraggable: true,
        });
      }
    }
    setTimeout(() => {
      let timeline = new Timeline(document.getElementById("visualization"));
      timeline.setOptions(this.options);
      timeline.setGroups(this.groups);
      timeline.setItems(this.items);
      timeline.on("timechange", function () {
        // console.log('Properties', properties);
      });
      timeline.on("timechanged", function () {
        //  console.log('Properties', properties);
      });
    }, 50);
    setTimeout(() => {
      this.hideTimeline = false;
      this.$root.$emit("setFullScreenLoader", false);
      this.$store.dispatch("SetLoader", { status: false, message: "" });
    }, 1000);
  },
  methods: {},
};
</script>
<style>
.vis-time-axis .vis-grid.vis-odd {
  background: #f5f5f5;
}
.vis-time-axis .vis-grid.vis-saturday,
.vis-time-axis .vis-grid.vis-sunday {
  background: gray;
}
.vis-time-axis .vis-text.vis-saturday,
.vis-time-axis .vis-text.vis-sunday {
  color: white;
}
.vis-item {
  background-color: #eeeeee00;
  /* border-color: #1371fe; */
  color: #0155d3;
  border-left-width: 3px;
  border-left-style: solid;
  font-weight: 500;
  opacity: 0.8;
  font-size: 13px;
  height: 55px;
}
.vis-h0 .vis-h01 .vis-h15 .vis-h16 {
  color: blue !important;
  height: 100px;
  text-align: center;
}

.vis-group .job {
  color: white;
  background-color: #1295BA !important;
}
.vis-group .request {
  color: white;
  background-color: #b0856d !important;
}
.vis-group .task {
  color: white;
  background-color: #055c58 !important;
}
.vis-group .job .vis-delete {
  display: none;
}
.vis-group .request .vis-delete {
  display: none;
}
.vis-group .task .vis-delete {
  display: none;
}

.vis-labelset div.vis-label:last-child {
  pointer-events: none;
  visibility: hidden;
  display: none;
  height: 0px;
}
.vis-foreground div.vis-group:last-child {
  pointer-events: none;
  visibility: hidden;
  display: none;
  height: 0px;
}

.vis-item.vis-range .vis-close {
  display: none;
}
/* .vis-delete {
  display: none;
} */

.loader2 {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1295BA;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1.2s linear infinite;
}
.progress-bar {
  background-color: rgba(156, 156, 156, 0.691);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  height: 100%;
  background-color: rgb(18, 149, 186);
  transform-origin: 0% 50%;
}
</style>